<template>
  <div>
    <div class="bg-gray-100 min-h-screen">
      <nav class="p-1 flex justify-between items-center">
        <div class="flex items-center">
          <img src="../assets/logo.png" class="w-28" />
          <p class="font-sans ml-5 text-xl inline-block">
            San Marco Compaction Tests
          </p>
        </div>
        <div class="flex items-center">
          
        </div>
      </nav>
      <main>
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showingNavigationDropdown: false,
      routes: [
        {
          name: "Dashboard",
          route: "dashboard",
        },
        {
          name: "Map",
          route: "map",
        },
      ],
    };
  },
};
</script>
