<template>
  <Map></Map>
</template>

<script>
import Map from "./views/Map.vue";

export default {
  name: "App",
  components: {
    Map
  }
};
</script>
