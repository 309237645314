<template>
  <Map-layout>
    <div
      :class="`z-20 ${showingNavigationDropdown ? 'ml-96' : 'ma-0'} h-screen`"
    >
      <arc-map></arc-map>
    </div>
  </Map-layout>
</template>

<script>
import MapLayout from "../layouts/MapLayout";
import ArcMap from "../components/Map";
export default {
  components: {
    MapLayout,
    ArcMap,
  },
  setup() {
    return {
      showingNavigationDropdown: false,
    };
  },
};
</script>

<style>
h3 {
  font-weight: 500;
  font-size: 1.125rem;
}

.h-screen {
  height: calc(100vh - 2.5rem) !important;
}
</style>
